@keyframes popup-come {
    from {opacity: 0;}
    to {opacity: 1;}
  }

.popup-wrap{
    animation: popup-come var(--time) ;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 99;
    background-color: var(--transparent);
}

.popup-content-neo{
    overflow: hidden;
    position: absolute;
    background-color: white;
    border-radius: 2px;
    min-height: 4em;
    min-width: 4em;
    width: max-content;
    max-width: 100vw;
    padding: 3em;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

}

@media screen and (max-width: 768px) {
    .popup-wrap{
        touch-action: none;
    }
    .popup-content-neo{
        padding: 1.5em;
        touch-action: none;

        top: 5em;
        transform: translateX(-50%);
    }
    .popup-content-neo *{
        touch-action: none;
    }
}

