.details-container {
    margin-top: 0.5em;
    background-color: var(--gray);
    padding-top: 1em;
    padding-bottom: 1em;
    border: 1px solid rgba(0, 0, 0, 0.02);
}

.tab-nav {
    display: flex;
}

.right-text {
    text-align: right;
    /* margin-top: 20px; */
}

.tab-nav .items {
    margin-right: 1em;
    margin-bottom: 1em;
    display: block;
    text-decoration: none;

    background-color: var(--gray);
    padding: 1em 2em;
    color: black;
}

.minor-tab {
    padding: 2em;
    display: flex;
}

.stuff {
    position: relative;
    margin-top: auto;
    margin-right: 3em;
    margin-bottom: auto;
    display: block;
    text-decoration: none;
    color: var(--accent);
    /* padding: 1em 2em; */
}

.items:hover::after {
    width: 40px;
}

.items::after {
    position: absolute;
    transition: var(--time);
    content: "";
    background-color: var(--white);
    width: 0px;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    border-radius: 10px;
}

a.anchor {
    display: block;
    position: relative;
    top: -200px;
    visibility: hidden;
}

.pseud-vid {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    filter: brightness(0.7);
    transition: var(--time);
}

.floor-plan-img {
    width: 100%;
    height: 150px;
    background-position: center;
    background-size: cover;
    object-position: center;
    object-fit: cover;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.tab-top {
    color: var(--accent);
}

.no-margin p {
    margin-bottom: 0.3em;
}

.pseud-wrap {
    overflow: hidden;
}

.pseud-wrap:hover .pseud-vid {
    width: 110%;
    height: 110%;
    transform: translateX(-5%) translateY(-5%);
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

hr {
    opacity: 0.1;
}

.fav-icon {
    /* margin-left: 1em; */
    display: inline-block;
    transition: var(--time);
    cursor: pointer;
    font-family: var(--flat-font);
}

.fav-icon:hover {
    transform: scale(1.1);
}

.fav-icon:active {
    transform: scale(0.9);
}

.rera {
    display: flex;
    cursor: pointer;
    background: var(--white);
    padding: 0.3em 1em;
    width: max-content;
}

.rera .smoll-tik {
    margin-right: 0em;
    transition: var(--time);
}

.rera:hover .smoll-tik {
    margin-right: 1em;
}

.rera .rera-txt {
    overflow: hidden;
    max-width: 1000px;
    transition: var(--time);
}

.rera:hover .rera-txt {
    max-width: 0px;
    margin: 0;
}

.rera .rera-number {
    font-size: 10px;
    opacity: 0;
    max-width: 0px;
    overflow: hidden;
    max-height: 20px;
    transition: var(--time);
}

.rera:hover .rera-number {
    /* visibility: visible; */
    opacity: 1;
    max-width: 1000px;
    overflow: hidden;
    height: 20px;

}

.offer-wrap {
    border: 2px dashed var(--accent);

    padding: 2em 1em 2em 1em;
}

.offer-img {
    width: 60px;
}

@keyframes donwloader-anim {
    0% {
        opacity: 0;
        transform: translateY(-5px);
    }

    40% {
        opacity: 1;
    }

    90% {
        opacity: 1;
        transform: translateY(0px);
    }

    100% {
        opacity: 0;
    }
}

.download-anim {
    animation: donwloader-anim 2s;
    animation-iteration-count: infinite;
}

.price-box {
    background-color: var(--gray);
    padding: 0.3em;
    width: max-content;
    border: 1px solid rgba(0, 0, 0, 0.02);
    font-size: 22px;
    float: right;
    margin-left: auto;
}

thead tr {
    border-bottom: hidden;
}

.propery-home {
    padding-top: 3em;
}

.our-underline {
    text-decoration: underline;
    text-decoration-thickness: 0.5px;
    text-underline-offset: 5px;
    text-decoration-color: rgba(255, 255, 255, 0.6);
}

.amenity-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    transform: translateY(-3px);
    opacity: 0.3;
}

.property-name {
    font-size: 1.9em;
}

.offer-terms {
    font-size: 14px;
    opacity: 0.6;
    float: right;
}

.sub-header {
    background-color: white;
}

.img-thumb {
    position: relative;
    width: 33.333%;
    padding: 3px;
    cursor: pointer;
    height: 200px;
}

.promises svg {
    opacity: 0.5;
}

.form-close {
    position: absolute;
    top: 0px;
    right: 10px;
    cursor: pointer;
}

.property-form {
    padding-top: 2em;
    height: 100%;
}

.property-form-content {
    transition: var(--time);
    position: sticky;
    top: 10.5em;
}

.property-home {
    padding-top: 2em;
}

.soorkie-identifier {
    white-space: nowrap;
    display: flex;
    flex-direction: row-reverse;
}

.soorkie-identifier .icon {
    margin-left: 10px;
}

.interested-form {
    /* padding: 1em; */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    overflow: hidden;
    /* overflow-x: hidden;  */
}

.builder-sep {
    display: block;
}

.name-label {
    /* margin-right: 10px; */
}

@media screen and (max-width: 992px) {
    .builder-sep {
        display: none;
    }
}


@media screen and (min-width: 992px) {
    .name-adjust {
        padding-right: 0;
    }
}

.showIcons {
    display: none;
}

.priceOnRequestCont .showWebContent {
    display: block;
}

@media screen and (max-width: 768px) {
    .priceOnRequestCont .showWebContent {
        display: none !important;
    }

    .name-label {
        /* margin-right: 0; */
    }

    .showIcons {
        display: block;
        align-items: center;
        justify-content: center;
    }

    .soorkie-identifier {
        margin-bottom: 0.3em;
        flex-direction: row;
    }

    .price-box {
        font-size: 20px;
    }

    /* Additional Styling for WhatsApp Icon */
    .getbutton-icon {
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-left: 0.3em;
        font-size: 20px;
    }

    .getbutton-icon-home {
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-left: 0.3em;
        font-size: 20px;
    }

    .getbutton-whatsapp-icon {
        margin-left: 10px;
        /* Adjust margin for spacing */
    }

    /* Specific styling for Phone icon */
    .getbutton-phone-icon {
        margin-left: 20px;
        /* Adjust margin for spacing */
    }

    .soorkie-identifier .icon {
        margin-left: 0;
        margin-right: 5px;
    }

    .property-home {
        padding-top: 1.5em;
    }

    .property-name {
        font-size: 1.2em;
    }

    .offer-terms {
        float: none;
        text-align: center;
    }

    .right-text {
        border: 1px solid rgba(0, 0, 0, 0.02);
        background: var(--gray);
        padding: 0 1em;
        text-align: left;
        margin-bottom: 1em;
        /* margin-top: 10px; */
    }

    .img-thumb {
        width: 50%;
        height: 220px;
    }

    .price-box {
        border: none;
        padding: 0;
        padding-top: 0.5em;
        width: 100%;
    }

    .floor-plan-img {
        width: 100%;
        height: 150px;
        margin-right: 30px;
    }
}