@keyframes show-anim {
    from {
        opacity: 0;
    }
    to { 
        opacity: 1;
    }
}

@keyframes hide-anim { 
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.alter-hide {
    opacity: 0;
}

.alter-show{
    opacity: 1;
}


.alter-show path{
    animation: show-anim 1s;
    display: block;
    opacity: 1;
}

.alter-hide path{
    animation: hide-anim 1s;
    display: none;
    opacity: 0;
}