@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400&family=Raleway:wght@500&display=swap");

:root {
    --accent: #00b84c;
    /* --accent: rgb(94, 128, 0); */

    /* --accent: #ff7b00; */

    --white: #ffffff;
    --gray: #f3f3f3;
    --gray-dark: #e7e7e7;

    /* --gray: #e2e2e2; */
    /* --gray: #969696; */

    --footer-color: #222;
    --btn-height: 30px;
    --btn-width: 170px;
    --time: 0.5s;
    --transparent: rgba(0, 0, 0, 0.9);
    --gen-font: "Cormorant Garamond", serif;
    --flat-font: "Raleway", sans-serif;
}

.gold-text {
    background: linear-gradient(to right, rgb(207, 211, 0), rgb(221, 144, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: var(--time);
}

.gold-text:hover {
    background: linear-gradient(to right, rgb(211, 179, 0), rgb(221, 125, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: var(--time);
}

html {
    overflow-x: hidden;
    font-feature-settings: "lnum" 1;
}

input {
    /* border: 1px solid red; */
    font-feature-settings: "lnum" 1;
}

body {
    /* position: relative; */
    font-family: "Cormorant Garamond", serif;
    font-display: swap;
}

.img-holder img {
    width: 100%;
    border-radius: 2px;
    padding-top: 1em;
    padding-bottom: 1em;
}

.gen-text {
    font-size: 1.2em;
}

.close-button {
    position: relative;
    width: 20px;
    height: 20px;
}

.close-button-line {
    top: 10px;
    left: 0;
    width: 20px;
    height: 1px;
    position: absolute;
    /* transform: rotateZ(45deg); */

    /* background-color: red; */
}

.bg-dark-theme {
    filter: brightness(0.7);
}

.center-desk {
    text-align: center;
}

@keyframes donna-being-donna {
    0% {
        transform: translateY(-5px) scale(0.9);
    }

    100% {
        transform: translateY(-5px) scale(1.4);
    }
}

.donna-thumb {
    margin-left: 10px;
    stroke: black;
    transition: var(--time);
}

.donna-thumb:hover {
    stroke: var(--accent);
    transform: scale(1.2);
}

.donna-anim {
    margin-left: 0.1em;
    margin-right: 0.1em;
    animation: donna-being-donna linear 1s infinite alternate-reverse;
}

.close-button-line::after,
.close-button-line::before {
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: white;
    transition: var(--time);
}

.close-button-line::before {
    opacity: 0.2;
}

@keyframes close-anim {
    0% {
        width: 100%;
        left: auto;
        right: 0;
    }

    49% {
        width: 0%;
        left: auto;

        right: 0;
    }

    51% {
        width: 0%;
        left: 0;
        right: auto;
    }

    100% {
        width: 100%;
        left: 0;
        right: auto;
    }
}

.close-button:hover .close-button-line::after,
.close-button-line:hover::after {
    animation: close-anim 1s;
}

.emi-select {
    border: none;
    padding: 0.5em 1em;
    font-feature-settings: "lnum" 1;
}

.emi-select::after {
    padding: 1em;
}

.soorkie-dots {
    /* width: 100%; */
    /* list-style-type: none; */
    /* margin: auto; */
    /* overflow-x: scroll; */
    overflow-x: hidden;
    overflow-y: visible;
    white-space: nowrap;

    transform: translateY(20px);
}

.soorkie-dots li {
    /* transform: translateY(20px); */
}

.phone-next-arrow,
.phone-prev-arrow {
    /* background-color: red; */
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    top: 250px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 1em;
}

.phone-prev-arrow {
    position: absolute;
    left: -12px;
    /* top: 40%; */
    z-index: 2;
}

.phone-next-arrow {
    /* top: 40%; */
    right: -12px;
    position: absolute;
    z-index: 2;
}

.link-path {
    color: #0d6efd;
}

/* NEW */
.link-stuff {
    cursor: pointer;
    text-decoration: none;
    background-image: linear-gradient(var(--accent), var(--accent));
    background-position: bottom left;
    background-size: calc(100% - 0.4rem) 1px;
    background-repeat: no-repeat;
    padding-bottom: 2px;
    transition: var(--time);
}


@keyframes link-stuff-anim {
    0% {
        background-position: bottom right;
        background-size: calc(100% - 0.4rem) 1px;
    }

    49% {
        background-position: bottom right;
        background-size: 0 1px;
    }

    50% {
        background-position: bottom left;
        background-size: 0 1px;
    }

    100% {
        background-position: bottom left;
        background-size: calc(100% - 0.4rem) 1px;
    }
}

.link-stuff:hover {
    animation: link-stuff-anim 1s;
}




.no-bars::-webkit-scrollbar {
    width: 0 !important;
}

.no-bars {
    scrollbar-width: none;
}

.gen-para h1,
.gen-para h2,
.gen-para h3,
.gen-para h4,
.gen-para h5,
.gen-para h6 {
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /* text-align: left; */
    font-family: "Cormorant Garamond", serif;
    letter-spacing: 0.3rem;
    text-transform: uppercase;
}

h1 {
    letter-spacing: 0.5rem;
    font-size: 300%;
}

.capital-text {
    font-family: "Raleway", sans-serif;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
    font-size: 0.7rem;
    line-height: 200%;
}

.trans-btn {
    border-radius: 0;
    width: var(--btn-width);
    height: var(--btn-height);
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    transition: var(--time);
}

.trans-btn::before {
    right: 0;
    top: 0;
    border-top: 1px solid;
    border-right: 1px solid;
}

.trans-btn::after {
    left: 0;
    bottom: 0;
    border-left: 1px solid;
    border-bottom: 1px solid;
}

.trans-btn::before,
.trans-btn::after {
    transition: var(--time);
    position: absolute;
    height: 20px;
    width: 50px;
    content: "";
    opacity: 0.7;
}

.trans-btn:hover::before,
.trans-btn:hover::after {
    width: 100%;
    height: 100%;
}

.trans-btn-accent {
    background-color: var(--accent);
    color: var(--white);
    width: max-content;
}

.btn {
    border-radius: 0;
    width: var(--btn-width);
    height: var(--btn-height);
    border: 1px solid #333;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    transition: 1s;
}

.btn-white::before,
.btn-white::after {
    background: white;
}

.btn-accent::before,
.btn-accent::after {
    background: var(--accent);
}

.btn::before,
.btn::after {
    position: absolute;
    /* background: red; */
    z-index: -1;
    transition: 1s;
    content: "";
    opacity: 90%;
}

.btn::before {
    height: var(--btn-height);
    width: calc(var(--btn-width) - 20px);
}

.btn::after {
    width: var(--btn-width);
    height: calc(var(--btn-height) - 20px);
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.btn:hover::before {
    width: 0px;
}

.btn:hover::after {
    height: 0px;
}

.gen-page img {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    border-radius: 2px;
}

.form-floating label {
    padding-bottom: 0.3em;
}

.form-select {
    border-radius: 2px;
    font-size: large;
    margin-bottom: 0.5em;
}

.form-control {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 0;
    transition: var(--time);
}

.form-control:focus {
    padding-left: 0.5em;
}

.react-tel-input .form-control {
    transition: var(--time);
}

.react-tel-input .form-control::placeholder {
    font-family: var(--flat-font);
    text-transform: uppercase;
    font-size: 0.7em;
    letter-spacing: 0.5em;
}

.react-tel-input .form-control:focus {
    padding-left: 3.5em;
}

.our-group {
    margin-top: 0em;
    margin-bottom: 0.3em;
    position: relative;
}

.our-group input {
    font-size: 0.9em;
    padding-top: 0.3em;
}

input:focus {
    outline: none;
}

.our-group input::placeholder {
    font-family: "Raleway", sans-serif;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
    font-size: 0.7rem;
    line-height: 200%;
    opacity: 1;
    transition: var(--time);
}

.our-group>label {
    position: absolute;
    top: -20px;
    opacity: 0;
    transition: var(--time);
}

.our-group:focus-within>label {
    position: absolute;
    padding-left: 1em;
    top: -30px;
    opacity: 1;
}

.our-group:focus-within>input::placeholder {
    opacity: 0;
    transition: var(--time);
    /* transform: translateX(10px); */
}

.text-material p {
    text-align: justify;
}

.react-tel-input .form-control {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border: none !important;
    border-bottom: 1px solid var(--gray-dark) !important;
}

@keyframes phone-arrow {
    from {
        transform: translateX(0);
        opacity: 0;
    }

    to {
        transform: translateX(25px);
        opacity: 0.5;
    }
}

@keyframes phone-arrow2 {
    from {
        transform: translateX(0);
        opacity: 0.5;
    }

    to {
        transform: translateX(25px);
        opacity: 0;
    }
}

.phone-arrow-one,
.phone-arrow-two {
    animation-duration: 2s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: var(--accent);
    opacity: 0.5;
}

.phone-arrow-one {
    animation-name: phone-arrow;
}

.phone-arrow-two {
    animation-name: phone-arrow2;
}

.phone-arrow-two {
    animation-delay: 1s;
}

@keyframes shine-animation {
    0% {
        transform: scale(0.8);
    }

    100% {
        transform: scale(1.6);
    }
}

.shine-1 {
    animation-name: shine-animation;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    transform-origin: 80% 20%;
}

.shine-2 {
    animation-name: shine-animation;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    transform-origin: 90% 50%;
}

.header-message {
    text-decoration: none;
    /* height: 100%; */
    padding: 10px 1em;
    border: 1px solid var(--accent);
    /* background-color: #00b84c; */
    display: flex;
    color: var(--accent);
    transition: var(--time);
}

.header-message div {
    text-decoration: none;
}

h5 {
    font-size: 1.17rem;
}

.city-drops-svg {
    transition: var(--time);
}

.headerdrops:hover .city-drops-svg {
    transform: rotateZ(360deg);
}

.city-drops:hover .city-drops-svg {
    /* opacity: 0; */
    transform: rotateZ(360deg);
}

.Toastify__toast {
    border-radius: 2px !important;
    background-color: black !important;
    color: white !important;
    font-family: var(--gen-font) !important;
    font-size: 1.2em;
}

.Toastify__toast-body div {
    text-align: center;
}

.Toastify__progress-bar {
    background: var(--accent) !important;
}

.Toastify__close-button--light {
    color: white !important;
}

.short-button {
    background-color: var(--gray);
    padding: 1em 1em;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.short-right {
    margin-right: 0;
}

.hover-green {
    fill: white;
    opacity: 0.3;
    transition: var(--time);
}

svg:hover .hover-green {
    fill: var(--accent);
    opacity: 1;
}

.generic-text-holder p {
    text-align: justify;
}

.city-drops-font {
    cursor: pointer;
    font-size: .7rem;
    letter-spacing: .4rem;
    font-family: 'raleway', 'sans-serif';
    line-height: 200%;
}

@media screen and (max-width: 768px) {
    .center-desk {
        text-align: justify;
    }

    .capital-text {
        font-size: 0.6rem;
    }

    body,
    html {
        width: 100vw;
        overflow-x: hidden;
    }

    h1 {
        font-size: 200%;
    }

    h5 {
        font-size: 17px;
    }
}

.phone-home {
    margin-right: 15px;
}

@media screen and (min-width: 1200px) {
    .phone-home {
        padding: 10px 1.5em;
        /* background-color: red; */
        border: 1px solid rgba(255, 255, 255, 0.7);
    }
}

@media screen and (max-width: 992px) {
    .center-phone {
        text-align: center;
    }
}