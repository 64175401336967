.pillbox {
    padding: 0.3em 0.7em;
    display: inline-block;
    border-radius: 2em;
    cursor: pointer;
}

.pillbox-selected {
    background-color: var(--accent);
    color: white;
    transition: var(--time);
    border: 1px solid rgba(0, 0, 0, 0);
}

.pillbox-unselected {
    background-color: var(--white);
    color: var(--accent);
    transition: var(--time);
    border: 1px solid var(--accent);
}
