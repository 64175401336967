.getbutton-wrap {
    position: fixed;
    right: 30px;
    bottom: 30px;
    /* background-color: red; */
    width: 60px;
    /* display: flex;
    flex-direction: column-reverse; */
    height: 60px;
    z-index: 100;
}

@keyframes come-msg {
    from {
        opacity: 0;
        transform: scale(0.7) translateY(-50%) translateX(80px);
    }

    to {
        opacity: 1;
    }
}

.getbutton-title {
    animation-name: come-msg;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
    position: absolute;
    right: 80px;
    top: 50%;
    color: black;
    transform: translateY(-50%);
    width: 100px;
    /* margin-right: 80px; */
    height: max-content;
    z-index: 10;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.5em;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    background-color: white;
}

.getbutton-help {
    animation-name: come-msg;
    animation-duration: 1s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
    opacity: 0;
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 160px;
    margin-right: 80px;
    height: 60px;
    z-index: 10;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.5em;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    background-color: white;
}

.getbutton-help::before,
.getbutton-title::before {
    content: "";
    position: absolute;
    height: 15px;
    width: 15px;
    background-color: white;
    /* z-index: -1; */
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    top: 50%;
    right: -7px;
    transform: translateY(-50%) rotate(45deg);
}

@keyframes come-button {
    from {
        opacity: 0;
        transform: scale(0.5);
    }

    to {
        opacity: 1;
    }
}

.getbutton-icon {
    animation: come-button 0.3s;
    margin-bottom: 20px;
    transition: var(--time);
}

.getbutton-enq-icon,
.getbutton-phone-icon-home,
.getbutton-whatsapp-icon-home {
    animation: come-button 0.3s;
}


.getbutton-icon-hide {
    position: absolute;
    bottom: 0;
}

.getbutton-phone-icon-home {
    position: absolute;
    bottom: 65px;
}

.getbutton-whatsapp-icon-home {
    position: absolute;
    bottom: 145px;
}

.getbutton-enq-icon {
    position: absolute;
    bottom: 225px;
    cursor: pointer;
}

.black-drop {}

.main-button {
    cursor: pointer;
}

.black-logos {
    border-radius: 50%;
    /* overflow: hidden; */
    box-shadow: 0 0 5px 0px rgba(255, 255, 255, 0.6);
}

@keyframes main-anim2 {
    from {
        opacity: 0.05;
    }

    to {
        /* border: 4px solid white; */
        transform: translateX(-50%) translateY(-50%) scale(1.1);
        opacity: 0.15;
    }
}

/* .attention-button::after {
    content: "";
    position: absolute;
    height: 120px;
    width: 120px;
    left: 50%;
    top: 50%;
    z-index: -1;
    background-color: var(--accent);
    border-radius: 50%;
    animation: main-anim2 1s infinite alternate-reverse;
    transform: translateX(-50%) translateY(-50%);
} */

.attention-button::before {
    animation-delay: 10s;
    animation: main-anim2 1s 0.25s infinite alternate-reverse;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    content: "";
    border-radius: 50%;
    width: 90px;
    height: 90px;
    opacity: 0.3;
    background-color: var(--accent);
    /* display: none; */
    /* background-color: rebeccapurple; */
}

.getbutton-icon-show {
    display: show;
}

.siq_bR {
    display: none !important;
    bottom: 140px !important;
    right: 60px !important;
    height: 60px !important;
}

/* .zsiq_flt_rel{
    background-color: var(--accent) !important;
} */

@keyframes mob-gb-anim {
    0% {
        opacity: 0;
        max-width: 80px;
    }

    10% {
        opacity: 1;
        max-width: 80px;
    }

    100% {
        opacity: 1;
        max-width: 100vw;
    }
}

.mobile-button {
    animation: mob-gb-anim 1.5s;
    position: fixed;
    margin: 10px;
    bottom: 0;
    right: 0;
    width: calc(100% - 20px);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    height: 60px;
    background-color: black;
    z-index: 3;
    padding: 0px 10px;
    /* padding-right: 80px; */
    display: flex;
    color: white;
    overflow: hidden;
}

.mob-icon {
    /* background-color: var(--accent); */
    height: 60px;
    /* width: 60px; */
    margin: auto;
    display: flex;
    flex-direction: column;
}

.plus,
.plus-close {
    transition: var(--time);
}

.plus-close {
    transform: rotateZ(45deg);
}

.mobile-button>a {
    text-decoration: none;
    color: white;
}

.mob-icon>div {
    margin: auto;
}

.mob-icon>.capital-text {
    display: none;
    font-size: 7px;
    transform: translateX(5px);
}

.up-show,
.up-hide {
    z-index: 2;
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
}

@keyframes up-show-anim {
    0% {
        opacity: 0;
        transform: translateX(-50%) translateY(100%);
    }

    100% {
        opacity: 1;
    }
}

@keyframes up-hide-anim {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) translateY(100%);
    }
}

.up-show {
    animation: up-show-anim var(--time);
    opacity: 1;
}

.up-hide {
    animation: up-hide-anim var(--time);

    /* animation-name: up-show-anim;
    animation-duration: var(--time);
    animation-direction: reverse; */
    opacity: 0;
}

.up-hide .mini-one {
    transform: translateX(50%);
}

.up-hide .mini-three {
    transform: translateX(-100%);
}

.mini-one,
.mini-two,
.mini-three {
    transition: var(--time);
    background-color: black;
    padding: 1em;
    border-radius: 2px;
}

.mini-one {
    position: absolute;
    left: -50%;
    transform: translateX(-50%);
    top: -40px;
}

.mini-three {
    position: absolute;
    left: 150%;
    transform: translateX(-50%);
    top: -40px;
}

.mini-two {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    /* right: -60px; */
    top: -60px;
}

@media screen and (max-width: 576px) {
    .getbutton-whatsapp-icon-home{
        bottom: 220px;
    }
    .getbutton-phone-icon-home{
        bottom: 140px;
    }
    .getbutton-enq-icon{
        bottom: 300px;
    }

}

@media screen and (max-width: 576px) {

    .getbutton-wrap,
    .getbutton-help {
        bottom: 15px;
        right: 15px;
        width: 30px;
        height: 30px;
        margin-right: 30px;
    }

    .siq_bR {
        display: block !important;
        bottom: 40px !important;
        right: 20px !important;
    }

    @media screen and (max-height: 700px) {
        #titlediv {
            display: none !important;
            opacity: 0;
            width: 0 !important;
        }
    }

    /* #titlediv {
        animation: none;
        top: -160px;
        right: -80px;
    }
    #titlediv::after {
        right: 30px;
        top: 60px;
    } */
    .attention-button::before {
        animation-delay: 10s;
        animation: main-anim2 1s 0.25s infinite alternate-reverse;
        left: 100%;
        top: 100%;
        transform: translateX(-50%) translateY(-50%);
        position: absolute;
        content: "";
        border-radius: 50%;
        width: 80px;
        height: 80px;
        opacity: 0.3;
        background-color: var(--accent);
        /* display: none; */
        /* background-color: rebeccapurple; */
    }
}