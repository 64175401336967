@keyframes disclaimer-app {
    0%{
        opacity: 0;
        transform: translateY(100%);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}


.disclaimer-container{
    
    background-color: black;
    z-index: 99;
    color: white;
    padding: 0.5em;
    text-align: justify;
    position: fixed;
    width: 100%;
    transition: var(--time) !important;
    bottom: 0;

}

.disc-mob-contents{
    overflow: hidden;
    transition: var(--time);
}

.disc-mob-contents[data-state="true"]{
    overflow-y: scroll;
    max-height: 50vh;
    padding-bottom: 5em;
}

.disc-mob-contents[data-state="false"]{
    overflow-y: hidden;
    max-height: 0;
}


.disclaimer-container[data-state="true"]{
    animation: disclaimer-app var(--time);
    transform: translateY(0);
}

.disclaimer-container[data-state="false"]{
    transform: translateY(100%) !important;
}

.link-only{
    display: inline-block;
    color: var(--accent);
    text-decoration: underline;
    position: relative;
    /* text-decoration: none;s */

}

.link-only:hover{
    color: var(--accent);
    opacity: 0.9;
}